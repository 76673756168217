<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar ref="previewEl" :src="userData['avatar']" class="rounded-nrm" rounded size="90px" />
      </template>
      <h4 class="mb-1">{{ userData['first_name'] }} {{ userData['last_name'] }}</h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input ref="refInputEl" class="d-none" type="file" />
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon class="d-inline d-sm-none" icon="EditIcon" />
        </b-button>
        <b-button class="ml-1" variant="outline-secondary">
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon class="d-inline d-sm-none" icon="TrashIcon" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group label="Username" label-for="username">
            <b-form-input id="username" v-model="userData['username']" />
          </b-form-group>
        </b-col>

        <!-- Field: First Name -->
        <b-col cols="12" md="4">
          <b-form-group label="First Name" label-for="first-name">
            <b-form-input id="first-name" v-model="userData['first_name']" />
          </b-form-group>
        </b-col>

        <!-- Field: Last Name -->
        <b-col cols="12" md="4">
          <b-form-group label="Last Name" label-for="last-name">
            <b-form-input id="last-name" v-model="userData['last_name']" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData['email']" type="email" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      variant="primary"
      @click="updateProfile"
    >
      Save Changes
    </b-button>
    <b-button :block="$store.getters['app/currentBreakPoint'] === 'xs'" type="reset" variant="outline-secondary">
      Reset
    </b-button>
  </div>
</template>

<script>
import {BAvatar, BButton, BCol, BForm, BFormGroup, BFormInput, BMedia, BRow} from 'bootstrap-vue'
import UserService from '@/services/user.service'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      roleOptions: [
        {label: 'Admin', value: 'admin'},
        {label: 'Author', value: 'author'},
        {label: 'Editor', value: 'editor'},
        {label: 'Maintainer', value: 'maintainer'},
        {label: 'Subscriber', value: 'subscriber'},
      ],
      statusOptions: [
        {label: 'Pending', value: 'pending'},
        {label: 'Active', value: 'active'},
        {label: 'Inactive', value: 'inactive'},
      ],
      permissionsData: [
        {
          module: 'Admin',
          read: true,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: 'Staff',
          read: false,
          write: true,
          create: false,
          delete: false,
        },
        {
          module: 'Author',
          read: true,
          write: false,
          create: true,
          delete: false,
        },
        {
          module: 'Contributor',
          read: false,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: 'User',
          read: false,
          write: false,
          create: false,
          delete: true,
        },
      ],
    }
  },
  methods: {
    updateProfile() {
      UserService.updateUserProfile(this.userData)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
