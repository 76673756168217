<template>
  <component :is="userData === null ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert :show="userData === null" variant="danger">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link :to="{name: 'apps-users-list'}" class="alert-link"> User List</b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="userData !== null" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon class="mr-0 mr-sm-50" icon="UserIcon" size="16" />
          <span class="d-none d-sm-inline">Profile</span>
        </template>
        <user-edit-tab-account :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Information -->
<!--      <b-tab>-->
<!--        <template #title>-->
<!--          <feather-icon class="mr-0 mr-sm-50" icon="InfoIcon" size="16" />-->
<!--          <span class="d-none d-sm-inline">Information</span>-->
<!--        </template>-->
<!--        <user-edit-tab-information class="mt-2 pt-75" />-->
<!--      </b-tab>-->

      <!-- Tab: Settings -->
<!--      <b-tab>-->
<!--        <template #title>-->
<!--          <feather-icon class="mr-0 mr-sm-50" icon="SettingsIcon" size="16" />-->
<!--          <span class="d-none d-sm-inline">Settings</span>-->
<!--        </template>-->
<!--        <user-edit-tab-settings class="mt-2 pt-75" />-->
<!--      </b-tab>-->
    </b-tabs>
  </component>
</template>

<script>
import {BAlert, BCard, BLink, BTab, BTabs} from 'bootstrap-vue';
import UserEditTabAccount from './UserEditTabAccount.vue';
import UserEditTabInformation from './UserEditTabInformation.vue';
import UserEditTabSocial from './UserEditTabSocial.vue';
import UserService from '@/services/user.service';
import UserEditTabSettings from '@/views/Profile/UserEditTabSettings.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSettings,
    UserEditTabSocial,
  },
  data() {
    return {
      userData: {},
    };
  },
  mounted() {
    UserService.getUserProfile().then(r => {
      this.userData = r.data;
    });
  },
};
</script>

<style></style>
