<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="SettingsIcon" size="19" />
      <h4 class="mb-0 ml-50">Settings</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>
        <!-- Field: Birth Date -->
        <b-col cols="12" lg="4" md="6">
          <b-form-group label="Birth Date" label-for="birth-date">
            <flat-pickr
              v-model="userDataInfo.dob"
              :config="{dateFormat: 'Y-m-d'}"
              class="form-control"
              placeholder="YYYY-MM-DD"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Mobile -->
        <b-col cols="12" lg="4" md="6">
          <b-form-group label="Mobile" label-for="mobile">
            <b-form-input id="mobile" v-model="userDataInfo.mobile" />
          </b-form-group>
        </b-col>

        <!-- Field: Website -->
        <b-col cols="12" lg="4" md="6">
          <b-form-group label="Website" label-for="website">
            <b-form-input id="website" v-model="userDataInfo.website" />
          </b-form-group>
        </b-col>

        <!-- Field: Language -->
        <b-col cols="12" lg="4" md="6">
          <b-form-group label="Language" label-for="language">
            <v-select
              v-model="userDataInfo.language"
              :clearable="false"
              :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
              :options="languageOptions"
              input-id="language"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col cols="12" lg="4" md="6">
          <b-form-group label="Gender" label-class="mb-1" label-for="gender">
            <b-form-radio-group id="gender" v-model="userDataInfo.gender" :options="genderOptions" />
          </b-form-group>
        </b-col>

        <!-- Field: Contact Options -->
        <b-col cols="12" lg="4" md="6">
          <b-form-group label="Contact Options" label-class="mb-1" label-for="contact-options">
            <b-form-checkbox-group
              id="contact-options"
              v-model="userDataInfo.contactOptions"
              :options="contactOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            variant="primary"
          >
            Save Changes
          </b-button>
          <b-button :block="$store.getters['app/currentBreakPoint'] === 'xs'" variant="outline-secondary">
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {BButton, BCol, BForm, BFormCheckboxGroup, BFormGroup, BFormInput, BFormRadioGroup, BRow} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
  },
  data() {
    return {
      userDataInfo: {
        dob: null,
        mobile: '+6595895857',
        website: 'https://rowboat.com/insititious/Angelo',
        language: 'French',
        gender: 'female',
        contactOptions: ['Email', 'Message'],
        addressLine1: 'A-65, Belvedere Streets',
        addressLine2: '',
        postcode: '',
        city: 'New York',
        state: '',
        country: '',
      },
      languageOptions: ['English', 'Русский'],
      genderOptions: [
        {text: 'Male', value: 'male'},
        {text: 'Female', value: 'female'},
      ],
      contactOptions: ['Email', 'Message', 'Phone'],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
